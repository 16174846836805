
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "house-insurance-package",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Terms & Conditions", [
        "Cooperative",
        "Insurance Packages",
        "House Insurance"
      ]);
    });
  },
  created() {
    //Set page title
    document.title =
      "Cooperative: House Insurance Package | " + process.env.VUE_APP_TITLE;
  }
});
